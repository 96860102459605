import React from 'react';

const TestPage = () => {
    return (
        <div style={{padding:'20px'}}>
            <h1>Test Page</h1>
            <p>This is a test page for demonstrating component functionality.</p>
        </div>
    );
};

export default TestPage;
